$(document).ready(function() {

	(function()
	{
		function checkChrome()
		{
			var isChromium = window.chrome,
				winNav = window.navigator,
				vendorName = winNav.vendor,
				isOpera = winNav.userAgent.indexOf("OPR") > -1,
				isIEedge = winNav.userAgent.indexOf("Edge") > -1,
				isIOSChrome = winNav.userAgent.match("CriOS");

			if (isIOSChrome)
				return true;
			else if (isChromium !== null && isChromium !== undefined && vendorName === "Google Inc." && isOpera == false && isIEedge == false)
				return true;
			else
				return false;
		}

		checkChrome() && $('html').addClass('chrome')
	}
	)();

	preloaderWrap.attr('data-preloader-on', 'on');

	sizeHandler();

	// GENERIC PAGE REQUESTS

	if ($('body.home').length ) {
		loadHomeGif();
		homeContentEntry(homeContentToPopulate);
		navLogo.attr('data-logo-color', 'white');
		KeysEasterEgg();
		setTimeout(function(){
			changeHomeContent();
		}, 50);
	}

	if ($('body.about').length ) {
		setInterval(function(){ 
			$('.lines').toggleClass('state-2');
		}, 5000);
	}

	if ($('body.contact').length ) {
		setInterval(function(){ 
			dataActiveOn(mapWrap);
		}, 700);
	}

	if ($('.cursor-follower').length ) {
		divMouseFollow();
	}

	if ($('body.cases').length ) {

		caseThumbsLI.each(function() {
	      	if ($(this).attr('data-case-active') == 'false') {
	      		$(this).remove();
	      	}
	      	caseIndex = ($(this).index()+1);
	      	caseIndex = caseIndex + "";
	      	if ( caseIndex <= 9 ) {
	            caseIndex = "0" + caseIndex;
	        }
	        
	      	caseNo1 = caseIndex.charAt(0);
	      	caseNo2 = caseIndex.charAt(1);
	      	$('h1 span:nth-child(1)', this).text(caseNo1);
	      	$('h1 span:nth-child(2)', this).text(caseNo2);

	    });

	    loadCases();
	}

	if ($('body.hire-us').length ) {

		function handleForm(initial)
		{
			var $form = $('#form1_hire-us-form');

			$form.find('input[type="email"]').removeAttr('required');
			$form.find('input[type="email"]').attr('type', 'text');

			if (initial)
			$form.find('input').first().select()
			else
			{
				var $field = $form.find('.field-error').parent();
				var $element = $field.find('input').add($field.find('textarea')).first()
				$element.select().val($element.val())
			}

			$form.on('submit', function(event) {

				$form.find('.field-error').remove()

		        // Serialize the form data and store the ID of the submitted form
		        var formData = $form.serialize(),
		            id = $form.attr('id');

	    		// Stop the browser from submitting the form.
	    		event.preventDefault();

		        $.ajax({
		            type: "POST",
		            // Get the URL we're submitting to from the current form
		            url: $form.attr('action'),
		            data: formData
		        }).done(function(data) {

		            // Find the form in perch's response (based on the id specified above)
		            var $newForm = $(data).find('#' + id);

		            // if Form exists in response, something went wrong
		            if ($newForm.length)
		            {
			            // Replace the existing form with the response from the server
			            $form.replaceWith($newForm);

			            handleForm()
		            }
		            else // Otherwise, everything worked and we should show thank you message
		            {
		            	$('body').addClass('thank-you')
		            }

		        });
			});
		}

		handleForm(true)
	}

	$('a').click(function(e) {
		thisTarget = $(this).attr('target');
		thisHref = $(this).attr('href');
		if (thisTarget!="_blank" && thisHref.indexOf('mailto') == -1 && thisHref.indexOf('tel:') == -1) {
			e.preventDefault();
			if (thisHref!='#') {
				pageLinkTransition(thisHref);
			}
		}
	}); 

	turnedOff.each(function() {
      	$(this).remove();
    });

	if ($('.case').length) {

		$('.para-columns li').each(function() {
			paraText = $('p', this).text();
	      	if (paraText == '') {
	      		$(this).css({ 
				    'visibility' : 'hidden'
			    });
	      	}
	    });

	    $('.para-columns').each(function() {
			paraText = $('li:first-child p', this).text();
	      	if (paraText == '') {
	      		$(this).remove();
	      	}
	    });

	    $('.flex-columns.stats li').each(function() {
			statText = $('.stat', this).text();
	      	if (statText == '') {
	      		$(this).remove();
	      	}
	    });

	    $('p.story-intro, figcaption').each(function() {
	      	if ($(this).text() == '') {
	      		$(this).remove();
	      	}
	    });

	    $('.img-wrap').each(function() {
	    	thisImg = $('img', this).prop('src');
	    	if( thisImg.indexOf('jpg') == -1 && thisImg.indexOf('png') == -1 && thisImg.indexOf('gif') == -1 ) {
	    		if (galleryTotalCalculated == false) {

	    			totalGallImgs = parseInt($(this).attr('data-img-id')) - 1;	    			
	    			galleryTotalCalculated = true;
	    			$('.gallery-nav li:gt('+(totalGallImgs-1)+')').remove();
	    		}
	      		$(this).remove();
	      	}
	    });



		galleryImgInsert1 = $('.img-wrap[data-img-id=1] img').prop('src');
		galleryImgInsert2 = $('.img-wrap[data-img-id=2] img').prop('src');
		galleryImgInsert3 = $('.img-wrap[data-img-id=3] img').prop('src');
		galleryImgInsert4 = $('.img-wrap[data-img-id=4] img').prop('src');
		galleryImgInsert5 = $('.img-wrap[data-img-id=5] img').prop('src');
		galleryImgInsert6 = $('.img-wrap[data-img-id=6] img').prop('src');
		galleryImgInsert7 = $('.img-wrap[data-img-id=7] img').prop('src');
		galleryImgInsert8 = $('.img-wrap[data-img-id=8] img').prop('src');
		galleryImgInsert9 = $('.img-wrap[data-img-id=9] img').prop('src');
		galleryImgInsert10 = $('.img-wrap[data-img-id=10] img').prop('src');

		$('.img-gallery .imgs li:nth-child(1) figure').css({ 
		    'background-image' : 'url('+galleryImgInsert1+')'
	    });
	    $('.img-gallery .imgs li:nth-child(2) figure').css({ 
		    'background-image' : 'url('+galleryImgInsert2+')'
	    });
	    $('.img-gallery .imgs li:nth-child(3) figure').css({ 
		    'background-image' : 'url('+galleryImgInsert3+')'
	    });
	    $('.img-gallery .imgs li:nth-child(4) figure').css({ 
		    'background-image' : 'url('+galleryImgInsert4+')'
	    });
	    $('.img-gallery .imgs li:nth-child(5) figure').css({ 
		    'background-image' : 'url('+galleryImgInsert5+')'
	    });
	    $('.img-gallery .imgs li:nth-child(6) figure').css({ 
		    'background-image' : 'url('+galleryImgInsert6+')'
	    });
	    $('.img-gallery .imgs li:nth-child(7) figure').css({ 
		    'background-image' : 'url('+galleryImgInsert7+')'
	    });
	    $('.img-gallery .imgs li:nth-child(8) figure').css({ 
		    'background-image' : 'url('+galleryImgInsert8+')'
	    });
	    $('.img-gallery .imgs li:nth-child(9) figure').css({ 
		    'background-image' : 'url('+galleryImgInsert9+')'
	    });
	    $('.img-gallery .imgs li:nth-child(10) figure').css({ 
		    'background-image' : 'url('+galleryImgInsert10+')'
	    });

	    projColor = perchData.attr('data-project-color');
	    projBGColor.each(function() {
	    	$(this).css({ 
			    'background-color' : projColor
		    });
	    });

	    $('p strong, .para-columns h3, p a').each(function() {
	    	$(this).css({ 
			   'color' : projColor
		    });
	    });

	    jsVideoStudy.on('click', function(e){
	    	videoStudyActive = true;
	    	player.loadVideoById( studyVideoID );
			caseVideoTakeover();
		});

	}

	if ($('body.blog, body.article, body.contact, body.hire-us, html.touchevents').length || windowWidth <= mobileDesktop) {
		setTimeout(function(){
			preloaderOff();
		}, 700);
	}

	// WHEN WE HAVE THE PLAYER
	if ($('body.home, body.case, body.about').length) {
		tabFocus();
	} else {
		muteBtn.remove();
	}

	if ($('html.backgroundblendmode').length) {} else {
		$('.no-blend-kill').remove();
	}

	// BUTTON HANDLERS

	jsPreviewVideo.on('click', function(e){
		dataActiveOn(FullScreenTransition);
		fixedElements.attr('data-screen-state', 'video-full');
		jsMonitorHomeState.attr('data-home-state', 'video-full');
		dataActiveOff(jsGrid);
		dataActiveOff(socialNav);
		soundUp();
		homeCaseChange = false;
	});

	jsCloseVideoTakeover.on('click', function(e){
		dataActiveOff(FullScreenTransition);
		fixedElements.attr('data-screen-state', '');
		jsMonitorHomeState.attr('data-home-state', '');
		dataActiveOn(socialNav);
		dataActiveOn(jsGrid);
		soundDown();
		homeCaseChange = true;
	});

	$('.js-explore-front-content').on('click', function(e){
		if (domWrapper.attr('data-front-state') == "covered") {
			domWrapper.attr('data-front-state', 'revealed');
		} else {
			domWrapper.attr('data-front-state', 'covered');
		}
	});

	$('.js-toggle-home-experience-overlay').on('click', function(e){

		if ( $('.js-home-overlay').attr('data-active') == "off") {
			thisService = $(this).attr('data-service');
			$('.js-content-service').hide();
			$('.js-content-service[content-service="'+thisService+'"]').show();
			$('.js-home-overlay').attr('data-active', 'on');
			$('.home-experience-overlay-close-panel').attr('data-active', 'on');
		} else {
			$('.js-home-overlay').attr('data-active', 'off');
			$('.home-experience-overlay-close-panel').attr('data-active', 'off');
		}
	});

	$('.js-toggle-home-experience-overlay').on('mouseenter', function(e){
		thisService = $(this).attr('data-service');
		$('.js-explore-slider').attr('data-hover', thisService);
	});
	$('.js-toggle-home-experience-overlay').on('mouseleave', function(e){
		$('.js-explore-slider').attr('data-hover', 'default');
	});

	letterPart.on('mouseenter', function(e){
		dataActiveOff(jsLetterHover);
	});

	letterPart.on('mouseleave', function(e){
		dataActiveOn(jsLetterHover);
	});

	$('.cases .js-preview-video').on('click', function(e){
		$('.cases .overflow-animate-wrap').attr('data-overflow-state', 'inactive');
	});

	$('.cases .close').on('click', function(e){
		$('.cases .overflow-animate-wrap').attr('data-overflow-state', 'active');
	});

	jsScroll.on('click', function(e){
		scrollVal = $(this).attr('data-scroll');
		smooth.scrollTo(scrollVal);
	});

	$('.js-case-scroll').on('click', caseVideoTakeover);

	$('.js-pause-video').on('click', function(e){
		if ( $(this).attr('data-pause-play-state') == 'paused' ) {
			player.playVideo();
			$('.js-pause-video').attr('data-pause-play-state', 'playing' );
		} else {
			player.pauseVideo();
			$('.js-pause-video').attr('data-pause-play-state', 'paused' );
		}
	});

	jsScrollPart.on('click', function(e){
		scrollVal = (parseInt($(this).attr('data-scroll'))-1)*windowHeight;
		smooth.scrollTo(scrollVal);
		smoothLetter.scrollTo(scrollVal);
	});

	jsNextHomeCase.on('click', function(e){
		changeHomeCase(1);
	});

	jsPrevHomeCase.on('click', function(e){
		changeHomeCase(-1);
	});

	jsOpenGallery.on('click', function(e){		
		imgGallery.attr('data-active', 'active');
		domWrapper.addClass('inactive');
		galleryImg = $(this).attr('data-img-id');
		imgGallery.attr('data-active-img', galleryImg);
		caseVideoWrap.attr('data-case-vid-overide', 'off');
		$('.img-hover', this).addClass('clicked');
		fixedElements.attr('data-screen-state', 'gallery');
		
		dataActiveOff(socialNav);
		removeClicked(1500);

		if (mobileDevice == false) {
			var cursorX = e.pageX;
	    	var cursorY = e.pageY;
	    	var moveX = (cursorX - (halfWindowWidth))*-0.1;
			var moveY = (cursorY - (halfWindowHeight))*-0.1;

	    	TweenMax.to(galleryImgFigure, 
				0.05, {
					x: moveX,
					y: moveY
				}
			);
			setTimeout(function(){
				divMouseMove(galleryImgFigure, 0.1);
				player.pauseVideo();
			}, 600);
		}

	});

	jsCloseGallery.on('click', function(e){
		imgGallery.attr('data-active', 'inactive');
		domWrapper.removeClass('inactive');
		if (!$('body').hasClass('touch-logic')) {
			player.playVideo();
		}
		
		// domBody.off();
		caseVideoWrap.attr('data-case-vid-overide', 'false');
		fixedElements.attr('data-screen-state', 'default');
		dataActiveOn(socialNav);
	});

	jsGalleryNext.on('click', function(e){
		currentGallImg = parseInt(imgGallery.attr('data-active-img'));
		if (currentGallImg != totalGallImgs) {
			newGallImg = currentGallImg + 1;
			imgGallery.attr('data-active-img', newGallImg);
		}
		
	});

	jsGalleryPrev.on('click', function(e){
		currentGallImg = parseInt(imgGallery.attr('data-active-img'));
		if (currentGallImg != 1) {
			newGallImg = currentGallImg - 1;
			imgGallery.attr('data-active-img', newGallImg);
		}
	});

	muteBtn.on('click', function(e){
		if (muteBtn.attr('data-muted') == 'unmuted') {
			player.mute();
			muteBtn.attr('data-muted', 'muted');
		} else {
			player.unMute();
			muteBtn.attr('data-muted', 'unmuted');
		}
	});

	FullScreenTransition.on('mouseenter', function(e){
		dataActiveOn(jsCursorBack);
	});

	FullScreenTransition.on('mouseleave', function(e){
		dataActiveOff(jsCursorBack);
	});
	FullScreenTransition.on('click', function(e){
		dataActiveOff(jsCursorBack);
	});

	if ($('body.about').length ) {

		$('p').each(function() {
	      	if ($(this).text() == '') {
	      		$(this).remove();
	      	}
	    });

		jsAboutOverflowOn.on('click', function(e){
			dataActiveOn(jsAboutVideo);
			dataActiveOn(jsLetterHover);
			navLogo.attr('data-logo-color', 'black');
		});

		jsAboutOverflowOff.on('click', function(e){
			dataActiveOff(jsAboutVideo);
			dataActiveOff(jsLetterHover);
			navLogo.attr('data-logo-color', 'white');
		});
	}

	if ($('body.case').length ) {

		// THESE TWO ARE ONNNNLY FOR THE INDIVIDUAL CASE BUTTON
		jsVideoTakeover.on('click', caseVideoTakeover);

		jsCloseCaseVideoTakeover.on('click', closeCaseVideoTakeover);

		skipToStart.on('click', function(e){
			player.seekTo(0);
		});

		$('.timeline-skipper').on('click', function(e){
			var timelineClickTime = ((event.pageX - $('.timeline-skipper').offset().left) / $('.timeline-skipper').width()) * player.getDuration();
			player.seekTo(timelineClickTime);
			processTimelineCurrentTime();
		});
	}

	jsOpenNav.on('click', function(e){
		dataActiveOn(navMenu);
		navLogo.attr('data-menu-active', 'active');
	});

	jsCloseNav.on('click', function(e){
		dataActiveOff(navMenu);
		navLogo.attr('data-menu-active', 'inactive');
	});

	$('.letters').on('mouseenter', function(e){
		$('.cursor-part').addClass('animate');
		setTimeout(function(){
			$('.cursor-part').removeClass('animate');
		}, 1600);
	});

	$('.case-thumbs li').on('click', function(e){
		thisProjColor = $('.case-bg', this).css('background-color');
		$('div', pageSweeperUnder).css({ 
		    'background-color' : thisProjColor
	    });
	});


	// Ouibounce modal
	var $ouiModal = $('#ouibounce-modal')
	window['ouibounce'] && $ouiModal.length && ouibounce($ouiModal[0], { delay: 300, aggressive: false, callback: function()
	{
		eggEnabled = false
		$ouiModal.addClass('show');

		var $closeButton = $ouiModal.find('.close')
		$closeButton.on('click', function closeHandler()
		{
			$ouiModal.css('display', 'none')
			$ouiModal.removeClass('show')
			$closeButton.off('click', closeHandler)
			eggEnabled = true
		})
	}});
});

function soundDown() {

	soundVolume -= 5;
	
	if (soundVolume <= 10) {
		soundVolume = 0;
	}
	player.setVolume(soundVolume);
	setTimeout(function(){
		if (soundVolume >= 10) {
			soundDown();
		}
	}, 30);
}

function soundUp() {
	soundVolume += 5;
	if (soundVolume >= 70) {
		soundVolume = 80;
	}
	player.setVolume(soundVolume);
	setTimeout(function(){
		if (soundVolume <= 70) {
			soundUp();
		}
	}, 40);
}

function tabFocus() {
	document.addEventListener("visibilitychange", function() {
	  if (document.hidden) {     
	    player.mute();
	  } else {
		if (muteBtn.attr('data-muted') == 'unmuted') {
			player.unMute();
		} 
     	
	  } 
	});
}

$(window).resize(function() {
	sizeHandler();
}); // END OF RESIZE

$(window).scroll(function() {
	if ($('.case').length) {
		caseScroller();
	}
});  // END OF SCROLL

function sizeHandler() {
	windowWidth = $(window).innerWidth();
	windowHeight = $(window).height(); 
	halfWindowWidth = windowWidth / 2;
	halfWindowHeight = windowHeight / 2;

	if ($('.about').length) {
		
		aboutPart2 = windowHeight * 2;
		aboutPart3 = windowHeight * 3;
		aboutPart4 = windowHeight * 4;
		aboutPart5 = windowHeight * 5;
		aboutPart6 = windowHeight * 6;

	}
	
	if (windowWidth <= mobileDesktop) {
		mobileDevice = true;
		$('body').addClass('touch-logic');
		desktopOnlyDiv.each(function() {
	      	$(this).remove();
	    });
	}

	if ($('html.touchevents').length) {
		$('body').addClass('touch-logic');
		mobileDevice = true;
		noTouchDiv.each(function() {
	      	$(this).remove();
	    });
	}
}

function removeClicked(e) {
	setTimeout(function(){
		$('.clicked').removeClass('clicked');
	}, e);
}

function divMouseFollow() {
	domBody.mousemove(function(e) {

	  	var cursorFollowX = e.pageX;
    	var cursorFollowY = e.pageY;

	    TweenMax.to(jsCursorFollower, 
			0.4, {
				x: cursorFollowX,
				y: cursorFollowY
			}
		);
	});
}

function divMouseMove(divToMove, moveAmount) {
	domBody.mousemove(function( e) {

	  	var cursorX = e.pageX;
    	var cursorY = e.pageY;

    	var moveX = (cursorX - (halfWindowWidth))*-moveAmount;
		var moveY = (cursorY - (halfWindowHeight))*-moveAmount;

    	TweenMax.to(divToMove, 
			1.175, {
				x: moveX,
				y: moveY
			}
		);
	});
}

function dataActiveOff(e) {
	e.attr('data-active', 'off');
}
function dataActiveOn(e) {
	e.attr('data-active', 'on');
}

function caseFunctions() {

	duration = player.getDuration();

	setInterval(function(){ 
		processTimelineCurrentTime();

	    if (
	    	currentTime >= $('.time-points li:nth-child(5)').attr('data-time-seconds') && 
	    	currentTime <= (  parseInt($('.time-points li:nth-child(5)').attr('data-time-seconds'))+5)  
	      ) {
	    	timelineCaptions.attr('data-active-vid-caption', '5');
	    } else if (
	    	currentTime >= $('.time-points li:nth-child(4)').attr('data-time-seconds') && 
	    	currentTime <= (  parseInt($('.time-points li:nth-child(4)').attr('data-time-seconds'))+5)  
	      ) {
	    	timelineCaptions.attr('data-active-vid-caption', '4');
	    } else if (
	    	currentTime >= $('.time-points li:nth-child(3)').attr('data-time-seconds') && 
	    	currentTime <= (  parseInt($('.time-points li:nth-child(3)').attr('data-time-seconds'))+5)  
	      ) {
	    	timelineCaptions.attr('data-active-vid-caption', '3');
	    } else if (
	    	currentTime >= $('.time-points li:nth-child(2)').attr('data-time-seconds') && 
	    	currentTime <= (  parseInt($('.time-points li:nth-child(2)').attr('data-time-seconds'))+5)  
	      ) {
	    	timelineCaptions.attr('data-active-vid-caption', '2');
	    } else if (
	    	currentTime >= $('.time-points li:nth-child(1)').attr('data-time-seconds') && 
	    	currentTime <= (  parseInt($('.time-points li:nth-child(1)').attr('data-time-seconds'))+5)  
	      ) {
	    	timelineCaptions.attr('data-active-vid-caption', '1');
	    } else {
	    	timelineCaptions.attr('data-active-vid-caption', '0');
	    }

	}, 200);

	setTimeout(function(){
	    timePointsLI.each(function() {
	      	positionTimePoint($(this));
	    });
	}, 1000);

	setInterval(function(){ 
		currentProgress = currentTime / duration;
		currentTimeline.css({ 
		    'transform' : 'scaleX('+currentProgress+')'
	    });
	    var currentProgressPixels = currentTimeline.width() * currentProgress;
	    currentNode.css({ 
		    'transform' : 'translateX('+currentProgressPixels+'px)'
	    });

	}, 1000);

}

function processTimelineCurrentTime() {
	currentTime = player.getCurrentTime();
    finalTime = currentTime.toFixed(0);

    if (finalTime >= 180) {
    	m = 3;
    	finalTime -= 180;
    } else if (finalTime >= 120) {
    	m = 2;
    	finalTime -= 120;
    } else if (finalTime >= 60) {
    	m = 1;
    	finalTime -= 60;
    } else {
    	m = 0;
    }
    finalTime = m + ":" + ("0" + finalTime).slice(-2);
    jsCurrentTime.text(finalTime);
}

function positionTimePoint(e) {
	pointTimePoint = e.attr('data-time-seconds');
	
	
	
	if(pointTimePoint == "") {
		e.hide();
		$('ul.timeline-captions li:nth-child('+((e.index())+1)+')').hide();
	}
	timePoint = (pointTimePoint / duration) * $('.current-timeline').width();
	e.css({ 
	    'transform' : 'translateX('+timePoint+'px)'
    });
}

function showElementWithTransition(e) {
	e.show();
	setTimeout(function(){
	   	e.addClass('active');
	}, 20);
}

function hideElementWithTransition(e) {
	e.removeClass('active');
	setTimeout(function(){
	   	e.hide();
	}, 1000);
}

function destroyElementWithTransition(e) {
	e.removeClass('active');
	setTimeout(function(){
	   	e.remove();
	}, 1000);
}

function loadProgress() {
	// TweenLite.to(".preloader-bar", 
	// 	0.04, {
	// 		scaleX: preload.progress,
	// 		ease:'Power1.easeInOut'
	// 	}
	// );
}

function siteLoadedTrigger(event) {
	setTimeout(function(){
	    domBody.removeClass('preloading');
	}, preloaderTimeout);
}

function aboutLoaded() {
	dataActiveOn(statMask);
	var options = {
	    useEasing : true, 
	    useGrouping : true, 
	    separator : ',', 
	    decimal : '.', 
	    prefix : '', 
	    suffix : '' 
	}

	countDigitCases = parseInt($('#js-about-count-up').text());

	var contactCount1 = new CountUp("js-about-count-up", 0, countDigitCases, 0, 5, options);

	setTimeout(function(){
	    contactCount1.start();
	}, 1000);
}

function bgColorData(e, datacolor) {
	e.css({ 
	    'background-color' : datacolor
    });
}

function homeContentEntry(n) {
	liN = n+1;
	homeCases[n][0] = $('.perch-content-cases li:nth-child('+liN+') div:nth-child(1)').html();
	homeCases[n][1] = $('.perch-content-cases li:nth-child('+liN+') div:nth-child(2)').text();
	homeCases[n][2] = $('.perch-content-cases li:nth-child('+liN+') div:nth-child(3)').text();
	homeCases[n][3] = $('.perch-content-cases li:nth-child('+liN+') div:nth-child(4)').html();
	homeCases[n][4] = $('.perch-content-cases li:nth-child('+liN+') div:nth-child(5)').text();
	homeCases[n][5] = $('.perch-content-cases li:nth-child('+liN+')').attr('data-case-video-id');
	homeCases[n][6] = $('.perch-content-cases li:nth-child('+liN+')').attr('data-case-active');
	homeCases[n][7] = $('.perch-content-cases li:nth-child('+liN+') a').attr('href');
	thisHomeCaseColor = $('.perch-content-cases li:nth-child('+liN+')').attr('data-case-color');

    ($('li:nth-child('+liN+')', homeBGColors)).css({ 
	    'background-color' : thisHomeCaseColor
    });
    thisHomeCaseImg = $('.perch-content-cases li:nth-child('+liN+')').attr('data-case-img');
    ($('li:nth-child('+liN+')', homeVidBGIMGs)).css({ 
	    'background-image' : 'url('+thisHomeCaseImg+')'
    });

	homeContentToPopulate +=1;

	if (homeCasesTotalCalculated == false) {
		if (homeCases[n][6] == 'false') {
			homeCasesTotal = n;
			homeCasesTotalCalculated = true;
		}
	}

	if (homeContentToPopulate <= (maxHomeCases-1)) {
		homeContentEntry(homeContentToPopulate);
	}
}

function changeHomeContent() {

	if (homeState == 0) {
		homeTypeWrapTitle.html( '' );
		// homeTypeWrapA.attr('href', homeCases[(homeState-1)][7] );
	} else {
		homeTypeWrapTitle.html( homeCases[(homeState-1)][0] );
		homeTypeWrapA.attr('href', homeCases[(homeState-1)][7] );
		
		if (homeCases[(homeState-1)][1] == "") {
			homeTypeWrapClient.hide();
		} else {
			homeTypeWrapClient.show();
			homeTypeWrapClient.text( homeCases[(homeState-1)][1] );
		}

		if (homeCases[(homeState-1)][3] == "") {
			homeTypeWrapDesc.hide();
		} else {
			homeTypeWrapDesc.show();
			homeTypeWrapDesc.html( homeCases[(homeState-1)][3] );
		}
	}

	

	// SEE THE WORK BASE BUTTON
	// NOT USED AFTER HOMEPAGE CHANGE
	// if (homeState == 1) {
	// 	$('.type-wrap ul:nth-child(1) li').hide();

	// 	$('.type-wrap a:not(.js-all-work)').hide();
	// 	$('.type-wrap a.js-all-work').show();
	// } else {
	// 	$('.type-wrap ul:nth-child(1) li').show();

	// 	$('.type-wrap a.js-all-work').hide();
	// 	$('.type-wrap a:not(.js-all-work)').show();
	
	// }	
}

function loadCases() {

	preload = new createjs.LoadQueue();
	preload.on("complete", preloaderOff); // ON ALL LOADED, RUN FUNCTION siteLoaded
	preload.on("error", preloaderError);
	$('.case-thumbs li:lt(9)').each(function() {
      	var imgToLoad = $('.cases-thumb-img-wrap div', this).css('background-image');	
      	
      	if (typeof imgToLoad != 'undefined') {
      		imgToLoad = imgToLoad.replace('url(','').replace(')','').replace('"','').replace('"','');
      		preload.loadFile(imgToLoad);
      	} 
    });
}

function loadHomeGif() {

	preload = new createjs.LoadQueue();
	preload.on("complete", preloaderOff); // ON ALL LOADED, RUN FUNCTION siteLoaded
	preload.on("error", preloaderError);
	preload.loadFile('/img/home-gifs/home-n.gif');
}

function preloaderError() {
	console.log(preloaderOff);
	preloaderOff();
}

function preloaderOff() {
	dataActiveOff(preloaderWrap);
	setTimeout(function(){
		preloaderWrap.remove();
	}, 1000);
	if ($('body.home').length) {
		if (nLetterIn == false) {
	        nLetterIn = true;
	        // setTimeout(function(){
	        //     homeActiveLetter.attr('data-active-letter', 'n');
	        // }, 230);
	    }
	}
}

function changeHomeCase(x) {

	homeState += x;
	
	if (homeState >= (homeCasesTotal+1)) {
		homeState = 0;
	}
	if (homeState < 0) {
		homeState = homeCasesTotal;
	}

	if ( homeState == 0 ) {
		domBody.attr('data-home-front-state', 'front');
	} else {
		domBody.attr('data-home-front-state', 'letters');
	}

	homeVidBGIMGs.attr('data-active-case', homeState);
	homeColorBGs.attr('data-active-case', homeState);
	if (mobileDevice == false) {
		soundDown();
	}

	setTimeout(function(){
		if ( homeState != 0 ) {
			changeVideoIDAndLetter( homeCases[(homeState-1)][5] );
		} else {
			changeVideoIDAndLetter('');
		}

		setTimeout(function(){
			nextNurtureLetter(x);
		}, 250);

	}, 210);

}

function nextNurtureLetter(nl) {
	nurtureLetterIndex = homeState;
	if (nurtureLetterIndex > 7) {
		nurtureLetterIndex -= 7;
	}
	if (nurtureLetterIndex == 1) {
		homeActiveLetter.attr('data-active-letter', 'n');
	} else if (nurtureLetterIndex == 2) {
		homeActiveLetter.attr('data-active-letter', 'u');
	} else if (nurtureLetterIndex == 3) {
		homeActiveLetter.attr('data-active-letter', 'r');
	} else if (nurtureLetterIndex == 4) {
		homeActiveLetter.attr('data-active-letter', 't');
	} else if (nurtureLetterIndex == 5) {
		homeActiveLetter.attr('data-active-letter', 'u');
	} else if (nurtureLetterIndex == 6) {
		homeActiveLetter.attr('data-active-letter', 'r');
	} else if (nurtureLetterIndex == 7) {
		homeActiveLetter.attr('data-active-letter', 'e');
	} else if (nurtureLetterIndex == 0) {
		homeActiveLetter.attr('data-active-letter', 'none');
	}
}

$('body.home').bind('mousewheel', function(e){

	if ( $('.home-experience-overlay-wrap').attr('data-active') != 'on' ) {
		if (homeCaseChange == true) {
	        if(e.originalEvent.wheelDelta >= 35) {
			  	changeHomeCase(-1);
	          	scrolled();
			}
			else if(e.originalEvent.wheelDelta <= -35) {
			  	changeHomeCase(1);
	          scrolled();
			}
	    }
	}

    
});

function scrolled() {
	homeCaseChange = false;
	setTimeout(function(){
   		homeCaseChange = true;
	}, 2000);
}

// YOUTUBE FUNCTIONS

// setTimeout(function(){
	   	// player.seekTo(40); // SKIPS THE VIDEO TO A PARTICULAR TIME IN SECONDS
	// }, 7500);

// currentTime = player.getCurrentTime(); // GETS WHERE THE VIDEO IS IN SECONDS
// duration = player.getDuration(); // GETS THE TOTAL TIME OF THE VIDEO

// player.playVideo(); // PLAYS THE VIDEO
// player.mute(); // MUTES THE VIDEO

function changeVideoIDAndLetter(id) {
	
	setTimeout(function(){
		dataActiveOff(ytPlayer);
	}, 150);

	setTimeout(function(){
		if (mobileDevice == false) {
			player.loadVideoById(id);
		}
	 	
	 	jsMonitorHomeCase.attr('data-active-case', homeState);
	}, 380);
	
	setTimeout(function(){
		dataActiveOff(typeWrap);
		setTimeout(function(){
			changeHomeContent();
			setTimeout(function(){
				if ( homeState != 0) {
					dataActiveOn(typeWrap);
				}
			}, 60);
		}, 880);
	}, 300);

}

function pageLinkTransition(hr) {
	pageSweeper.show();

	setTimeout(function(){
		dataActiveOn(pageSweeper);
		setTimeout(function(){
			location.href=hr;
		}, 750);
	}, 10);
	
}

function caseVideoTakeover() {
	caseVideoWrap.attr('data-case-vid-overide', 'full');
	caseRightPanel.attr('data-content-state-overide', 'inactive');
	fixedElements.attr('data-screen-state', 'video-full');
	dataActiveOn(FullScreenTransition);
	dataActiveOff(socialNav);
	player.unMute();
	jsGrid.attr('data-screen-state', 'video-full');
}

function closeCaseVideoTakeover() {
	if (videoStudyActive == true) {
		player.loadVideoById( caseVideoID );
		videoStudyActive = false;
	}
	player.mute();
	caseVideoWrap.attr('data-case-vid-overide', 'false');
	caseRightPanel.attr('data-content-state-overide', 'active');
	fixedElements.attr('data-screen-state', 'default');
	dataActiveOff(FullScreenTransition);
	dataActiveOn(socialNav);
	jsGrid.attr('data-screen-state', '');
}

var eggEnabled = true

function KeysEasterEgg() {
	$(document).keypress(function(event){
		if (eggEnabled)
		{
			easterKey = String.fromCharCode(event.which);
			var easterBlend;
			$('.vid-bg').hide();
			if (easterKey == 'c') {
				easterBlend = 'color-burn';
			} else if (easterKey == 'd') {
				easterBlend = 'difference';
			} else if (easterKey == 'e') {
				easterBlend = 'exclusion';
			} else if (easterKey == 'h') {
				easterBlend = 'hue';
			} else if (easterKey == 'l') {
				easterBlend = 'luminosity';
			} else if (easterKey == 's') {
				easterBlend = 'saturation';
			} else if (easterKey == 'o') {
				easterBlend = 'soft-light';
			} else {
				easterBlend = 'multiply';
				$('.vid-bg').show();
			}
		  	homeVideoMaskWrap.css({ 
			    'mix-blend-mode' : ''+easterBlend+''
		    });
		}
	});
}